import * as React from 'react';
import { Headline, Paragraph, Title, Subtitle } from '@bojagi/pablo/Typography';
import styled from 'styled-components';
import { getColor } from '@bojagi/pablo/styleHelpers';
import { Container } from '../components/Container';
import { Layout } from '../components/Layout';
import { Seo } from '../components/Seo';
import { SimpleText } from '../sections/SimpleText';
import { disableGA } from '../utils/ga';

const GaOptOutButton = styled.span`
  color: ${getColor('brand', 'main')};
  text-decoration: none;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

const IndexPage = () => (
  <Layout showLogo>
    <Seo title="Privacy" />
    <SimpleText>
      <Headline>Privacy</Headline>
      <Title>Introduction</Title>
      <Paragraph>
        Jentsch / Merz / Males GbR (“we” or “us” or “our”) respects the privacy of our users (“user”
        or “you”). This Privacy Policy explains how we collect, use, disclose, and safeguard your
        information when you visit our website bojagi.io, including any other media form, media
        channel, mobile website, or mobile application related or connected thereto (collectively,
        the “Site”). Please read this privacy policy carefully. If you do not agree with the terms
        of this privacy policy, please do not access the site.
      </Paragraph>
      <Paragraph>
        We reserve the right to make changes to this Privacy Policy at any time and for any reason.
        We will alert you about any changes by updating the “Last Updated” date of this Privacy
        Policy. Any changes or modifications will be effective immediately upon posting the updated
        Privacy Policy on the Site, and you waive the right to receive specific notice of each such
        change or modification.
      </Paragraph>
      <Paragraph>
        You are encouraged to periodically review this Privacy Policy to stay informed of updates.
        You will be deemed to have been made aware of, will be subject to, and will be deemed to
        have accepted the changes in any revised Privacy Policy by your continued use of the Site
        after the date such revised Privacy Policy is posted.
      </Paragraph>
      <Paragraph>
        This Privacy Policy was created using Termly’s Privacy Policy Generator.
      </Paragraph>
      <Title>Collection of your Information</Title>
      <Paragraph>
        We may collect information about you in a variety of ways. The information we may collect on
        the Site includes:
      </Paragraph>
      <Subtitle>Personal Data</Subtitle>
      <Paragraph>
        Personally identifiable information, such as your name, shipping address, email address, and
        telephone number, and demographic information, such as your age, gender, hometown, and
        interests, that you voluntarily give to us when you register with our service or newsletter
        or when you choose to participate in various activities related to the Site, such as online
        chat and message boards. You are under no obligation to provide us with personal information
        of any kind, however your refusal to do so may prevent you from using certain features of
        the Site.
      </Paragraph>
      <Subtitle>Derivative Data</Subtitle>
      <Paragraph>
        Information our servers automatically collect when you access the Site, such as your IP
        address, your browser type, your operating system, your access times, and the pages you have
        viewed directly before and after accessing the Site.
      </Paragraph>
      <Subtitle>Financial Data</Subtitle>
      Financial information, such as data related to your payment method (e.g. valid credit card
      number, card brand, expiration date) that we may collect when you purchase, order, return,
      exchange, or request information about our services from the Site. We store only very limited,
      if any, financial information that we collect. Otherwise, all financial information is stored
      by our payment processor
      <em>Stripe</em> and <em>GitHub</em> and you are encouraged to review their privacy policy
      contact them directly for responses to your questions.
      <Subtitle>Data from GitHub</Subtitle>
      When using our service, you need to connect it with GitHub. For this you give us personal
      information connected to your GitHub account including your name, your GitHub username,
      location, email address, profile picture.
      <Title>Use of your Information</Title>
      <Paragraph>
        Having accurate information about you permits us to provide you with a smooth, efficient,
        and customized experience. Specifically, we may use information collected about you via the
        Site to:
      </Paragraph>
      <ul>
        <li>
          Compile anonymous statistical data and analysis for use internally or with third parties
          (such as Google Analytics)
        </li>
        <li>Create and manage your account</li>
        <li>Email you regarding your account or subscription</li>
        <li>Enable user-to-user communications</li>
        <li>
          Fulfill and manage purchases, orders, payments, and other transactions related to the Site
        </li>
        <li>Increase the efficiency and operation of the Site</li>
        <li>Monitor and analyze usage and trends to improve your experience with the Site</li>
        <li>Notify you of updates to our service</li>
        <li>
          Prevent fraudulent transactions, monitor against theft, and protect against criminal
          activity
        </li>
        <li>Process payments and refunds</li>
        <li>Request feedback and contact you about your use of the Site</li>
        <li>Resolve disputes and troubleshoot problems</li>
        <li>Respond to product and customer service requests</li>
        <li>Send you a newsletter</li>
        <li>Solicit support for our services</li>
      </ul>
      <Title>Disclosure of your information</Title>
      <Paragraph>
        We may share information we have collected about you in certain situations. Your information
        may be disclosed as follows:
      </Paragraph>
      <Subtitle>By Law or to Protect Rights</Subtitle>
      <Paragraph>
        If we believe the release of information about you is necessary to respond to legal process,
        to investigate or remedy potential violations of our policies, or to protect the rights,
        property, and safety of others, we may share your information as permitted or required by
        any applicable law, rule, or regulation. This includes exchanging information with other
        entities for fraud protection and credit risk reduction.
      </Paragraph>
      <Subtitle>Third-Party Service Providers</Subtitle>
      <Paragraph>
        We may share your information with third parties that perform services for us or on our
        behalf, including payment processing, data analysis, email delivery, hosting services,
        customer service, and marketing assistance.
      </Paragraph>
      <Subtitle>Interactions with Other Users</Subtitle>
      <Paragraph>
        If you interact with other users of our service, those users may see your name, profile
        photo, and descriptions of your activity, including sending invitations to other users or
        chatting with other users.
      </Paragraph>
      <Subtitle>Online Postings</Subtitle>
      <Paragraph>
        When you post comments, contributions or other content to our service, your posts may be
        viewed by all users and may be publicly distributed outside the service in perpetuity.
      </Paragraph>
      <Subtitle>Business Partners</Subtitle>
      <Paragraph>
        We may share your information with our business partners to offer you certain products,
        services or promotions.
      </Paragraph>
      <Subtitle>Other Third Parties</Subtitle>
      <Paragraph>
        We may share your information with advertisers and investors for the purpose of conducting
        general business analysis. We may also share your information with such third parties for
        marketing purposes, as permitted by law.
      </Paragraph>
      <Subtitle>Sale or Bankruptcy</Subtitle>
      <Paragraph>
        If we reorganize or sell all or a portion of our assets, undergo a merger, or are acquired
        by another entity, we may transfer your information to the successor entity. If we go out of
        business or enter bankruptcy, your information would be an asset transferred or acquired by
        a third party. You acknowledge that such transfers may occur and that the transferee may
        decline honor commitments we made in this Privacy Policy.
      </Paragraph>
      <Paragraph>
        We are not responsible for the actions of third parties with whom you share personal or
        sensitive data, and we have no authority to manage or control third-party solicitations. If
        you no longer wish to receive correspondence, emails or other communications from third
        parties, you are responsible for contacting the third party directly.
      </Paragraph>
      <Title>Tracking Technologies</Title>
      <Subtitle>Cookies and Web Beacons</Subtitle>
      <Paragraph>
        We may use cookies, tracking pixels, and other tracking technologies on the Site and our
        service to help customize the Site and our service and improve your experience. When you
        access the Site or our service, your personal information is not collected through the use
        of tracking technology. Most browsers are set to accept cookies by default. You can remove
        or reject cookies, but be aware that such action could affect the availability and
        functionality of the Site or our service.
      </Paragraph>
      <Paragraph>
        We may use cookies, tracking pixels, and other tracking technologies on the Site and our
        service to help customize the Site and our service and improve your experience. For more
        information on how we use cookies, please refer to our Cookie Policy posted on the Site,
        which is incorporated into this Privacy Policy. By using the Site, you agree to be bound by
        our Cookie Policy.
      </Paragraph>
      <Subtitle>Website Analytics</Subtitle>
      <Paragraph>
        We may also partner with selected third-party vendors, such as Google Analytics and others,
        to allow tracking technologies and remarketing services on the Site and our service through
        the use of first party cookies and third-party cookies, to, among other things, analyze and
        track users’ use of the Site and our service, determine the popularity of certain content
        and better understand online activity. By accessing the Site, our service, you consent to
        the collection and use of your information by these third-party vendors. You are encouraged
        to review their privacy policy and contact them directly for responses to your questions. We
        do not transfer personal information to these third-party vendors. However, if you do not
        want any information to be collected and used by tracking technologies, you can visit the
        third-party vendor or the Network Advertising Initiative Opt-Out Tool or Digital Advertising
        Alliance Opt-Out Tool.
      </Paragraph>
      <Paragraph>
        You can opt-out of Google Analytics by clicking the link below. By clicking on this link an
        opt-out-cookie is set in your browser that is stopping future tracking by Google
        Analytics.&nbsp;
        <GaOptOutButton role="button" onClick={disableGA}>
          To opt-out of Google Analytics usage, click here.
        </GaOptOutButton>
      </Paragraph>
      <Paragraph>
        You should be aware that getting a new computer, installing a new browser, upgrading an
        existing browser, or erasing or otherwise altering your browser’s cookies files may also
        clear certain opt-out cookies, plug-ins, or settings.
      </Paragraph>
      <Title>Third-Party Websites</Title>
      <Paragraph>
        The Site and or service may contain links to third-party websites and applications of
        interest, including advertisements and external services, that are not affiliated with us.
        Once you have used these links to leave the Site or our service, any information you provide
        to these third parties is not covered by this Privacy Policy, and we cannot guarantee the
        safety and privacy of your information. Before visiting and providing any information to any
        third-party websites, you should inform yourself of the privacy policies and practices (if
        any) of the third party responsible for that website, and should take those steps necessary
        to, in your discretion, protect the privacy of your information. We are not responsible for
        the content or privacy and security practices and policies of any third parties, including
        other sites, services or applications that may be linked to or from the Site or our service.
      </Paragraph>
      <Title>Security of your Information</Title>
      <Paragraph>
        We use administrative, technical, and physical security measures to help protect your
        personal information. While we have taken reasonable steps to secure the personal
        information you provide to us, please be aware that despite our efforts, no security
        measures are perfect or impenetrable, and no method of data transmission can be guaranteed
        against any interception or other type of misuse. Any information disclosed online is
        vulnerable to interception and misuse by unauthorized parties. Therefore, we cannot
        guarantee complete security if you provide personal information.
      </Paragraph>
      <Title>Policy for Children</Title>
      <Paragraph>
        We do not knowingly solicit information from or market to children under the age of 13. If
        you become aware of any data we have collected from children under age 13, please contact us
        using the contact information provided below.
      </Paragraph>
      <Title>Controls for DO-NOT-TRACK Features</Title>
      <Paragraph>
        Most web browsers and some mobile operating systems and our service include a Do-Not-Track
        (“DNT”) feature or setting you can activate to signal your privacy preference not to have
        data about your online browsing activities monitored and collected. If you set the DNT
        signal on your browser, we will respond to such DNT browser signals.
      </Paragraph>
      <Title>Options regarding your Information</Title>
      <Subtitle>Account Information</Subtitle>
      <Paragraph>
        You may at any time review or change the information in your account or terminate your
        account by:
      </Paragraph>
      <ul>
        <li>Logging into your account settings and updating your account</li>
        <li>Contacting us using the contact information provided below</li>
      </ul>
      Upon your request to terminate your account, we will deactivate or delete your account and
      information from our active databases. However, some information may be retained in our files
      to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of
      Use and/or comply with legal requirements.
      <Subtitle>Emails and Communications</Subtitle>
      <Paragraph>
        If you no longer wish to receive correspondence, emails, or other communications from us,
        you may opt-out by:
      </Paragraph>
      <ul>
        <li>
          Noting your preferences at the time you register your account with the Site or our service
        </li>
        <li>Logging into your account settings and updating your preferences</li>
        <li>Contacting us using the contact information provided below</li>
      </ul>
      <Paragraph>
        If you no longer wish to receive correspondence, emails, or other communications from third
        parties, you are responsible for contacting the third party directly.
      </Paragraph>
      <Title>Cookie Policy</Title>
      <Subtitle>Introduction</Subtitle>
      <Paragraph>
        Jentsch / Merz / Males GbR (“we” or “us” or “our”) may use cookies, web beacons, tracking
        pixels, and other tracking technologies when you visit our website bojagi.io, including any
        other media form, media channel, mobile website, or mobile application related or connected
        thereto (collectively, the “Site”) to help customize the Site and improve your experience.
      </Paragraph>
      <Paragraph>
        We reserve the right to make changes to this Cookie Policy at any time and for any reason.
        We will alert you about any changes by updating the “Last Updated” date of this Cookie
        Policy. Any changes or modifications will be effective immediately upon posting the updated
        Cookie Policy on the Site, and you waive the right to receive specific notice of each such
        change or modification.
      </Paragraph>
      <Paragraph>
        You are encouraged to periodically review this Cookie Policy to stay informed of updates.
        You will be deemed to have been made aware of, will be subject to, and will be deemed to
        have accepted the changes in any revised Cookie Policy by your continued use of the Site
        after the date such revised Cookie Policy is posted.
      </Paragraph>
      <Paragraph>This Cookie Policy was created using Termly’s Cookie Consent Manager.</Paragraph>
      <Subtitle>Use of Cookies</Subtitle>
      <Paragraph>
        A “cookie” is a string of information which assigns you a unique identifier that we store on
        your computer. Your browser then provides that unique identifier to use each time you submit
        a query to the Site. We use cookies on the Site to, among other things, keep track of
        services you have used, record registration information, record your user preferences, keep
        you logged into the Site, facilitate purchase procedures, and track the pages you visit.
        Cookies help us understand how the Site is being used and improve your user experience.
      </Paragraph>
      <Subtitle>Types of Cookies</Subtitle>
      <Paragraph>The following types of cookies may be used when you visit the Site:</Paragraph>
      <h4>Analytics Cookies</h4>
      <Paragraph>
        Analytics cookies monitor how users reached the Site, and how they interact with and move
        around once on the Site. These cookies let us know what features on the Site are working the
        best and what features on the Site can be improved.
      </Paragraph>
      <h4>Our Cookies</h4>
      <Paragraph>
        Our cookies are “first-party cookies”, and can be either permanent or temporary. These are
        necessary cookies, without which the Site won&apos;t work properly or be able to provide
        certain features and functionalities. Some of these may be manually disabled in your
        browser, but may affect the functionality of the Site.
      </Paragraph>
      <h4>Personalization Cookies</h4>
      <Paragraph>
        Personalization cookies are used to recognize repeat visitors to the Site. We use these
        cookies to record your browsing history, the pages you have visited, and your settings and
        preferences each time you visit the Site.
      </Paragraph>
      <h4>Security Cookies</h4>
      <Paragraph>
        Security cookies help identify and prevent security risks. We use these cookies to
        authenticate users and protect user data from unauthorized parties.
      </Paragraph>
      <h4>Site Management Cookies</h4>
      <Paragraph>
        Site management cookies are used to maintain your identity or session on the Site so that
        you are not logged off unexpectedly, and any information you enter is retained from page to
        page. These cookies cannot be turned off individually, but you can disable all cookies in
        your browser.
      </Paragraph>
      <h4>Third-Party Cookies</h4>
      <Paragraph>
        Third-party cookies may be place on your computer when you visit the Site by companies that
        run certain services we offer. These cookies allow the third parties to gather and track
        certain information about you. These cookies can be manually disabled in your browser.
      </Paragraph>
      <Subtitle>Control of Cookies</Subtitle>
      <Paragraph>
        Most browsers are set to accept cookies by default. However, you can remove or reject
        cookies in your browser’s settings. Please be aware that such action could affect the
        availability and functionality of the Site.
      </Paragraph>
      <Paragraph>
        For more information on how to control cookies, check your browser or device’s settings for
        how you can control or reject cookies.
      </Paragraph>
      <Subtitle>Other Tracking Technologies</Subtitle>
      <Paragraph>
        In addition to cookies, we may use web beacons, pixel tags, and other tracking technologies
        on the Site to help customize the Site and improve your experience. A “web beacon” or “pixel
        tag” is tiny object or image embedded in a web page or email. They are used to track the
        number of users who have visited particular pages and viewed emails, and acquire other
        statistical data. They collect only a limited set of data, such as a cookie number, time and
        date of page or email view, and a description of the page or email on which they reside. Web
        beacons and pixel tags cannot be declined. However, you can limit their use by controlling
        the cookies that interact with them.
      </Paragraph>
      <Subtitle>Data Subprocessors</Subtitle>
      <Paragraph>
        We use the services of following companies and also send personal user data to them for
        processing:
      </Paragraph>
      <ul>
        <li>Amazon AWS</li>
        <li>Google, Inc</li>
        <li>Auth0, Inc</li>
        <li>Stripe</li>
        <li>GitHub, Inc</li>
        <li>HubSpot, Inc</li>
        <li>Datadog, Inc</li>
        <li>Cloudflare, Inc</li>
        <li>Debitoor GmbH</li>
      </ul>
      <Subtitle>Privacy Policy</Subtitle>
      <Paragraph>
        For more information about how we use information collected by cookies and other tracking
        technologies, please refer to our Privacy Policy above. This Cookie Policy is part of and is
        incorporated into our Privacy Policy. By using the Site, you agree to be bound by this
        Cookie Policy and our Privacy Policy.
      </Paragraph>
      <Title>Contact Us</Title>
      <Paragraph>
        If you have questions or comments about this Privacy Policy, please contact us at:
      </Paragraph>
      <Paragraph>
        Jentsch / Merz / Males GbR
        <br />
        Malmöer Straße 24
        <br />
        10439 Berlin
        <br />
        hello@bojagi.io
      </Paragraph>
    </SimpleText>
    <Container />
  </Layout>
);

export default IndexPage;
